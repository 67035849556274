import React, { useState } from "react";
import "./Pricing.scss";
import { Button } from "antd";
import { generatePaymentLink } from "services/SauceService";
import { isMobile } from "react-device-detect";

enum Plan {
  FomoPromo = "fomoPromo",
  HypeTrain = "hypeTrain",
  FomoPromoNow = "fomoPromoNow",
  HypeTrainNow = "hypeTrainNow",
}

const Pricing: React.FC = () => {
  const [selectedCard, setSelectedCard] = useState<Plan | null>(null);
  const [loading, setLoading] = useState<boolean>(false);

  const handleCardClick = (index: Plan) => {
    setSelectedCard(index);
  };

  const onClickPayNow = async () => {
    const params = {
      plan: selectedCard,
    };
    setLoading(true);
    const response: any = await generatePaymentLink(params);
    if (response) {
      if (isMobile) {
        if (window.top) window.top.location.href = response.url;
      } else window.open(response.url, "_blank");
    }
    setLoading(false);
  };

  return (
    <div className="pricing">
      <div className="pricing-container">
        {[
          {
            title: "6 Month Plan",
            plan: Plan.HypeTrainNow,
            price: (
              <span>
                <s style={{ fontSize: 14 }}>$4,674</s> $4,319 / 6 months
              </span>
            ),
            influencers: "18 Influencers",
            reach: "180K+",
          },
          {
            title: "6 Month Installment Plan",
            plan: Plan.HypeTrain,
            price: "$779 / Month for 6 months",
            influencers: "18 Influencers",
            reach: "180K+",
          },
          {
            title: "3 Month Plan",
            plan: Plan.FomoPromoNow,
            price: (
              <span>
                <s style={{ fontSize: 14 }}>$2,397</s> $2,199 / 3 months
              </span>
            ),
            influencers: "9 Influencers",
            reach: "90K+",
          },
          {
            title: "3 Month Installment Plan",
            plan: Plan.FomoPromo,
            price: "$799 / Month for 3 months",
            influencers: "9 Influencers",
            reach: "90K+",
          },
        ].map((plan) => (
          <div
            key={plan.plan}
            className={`card card-border-orange ${
              selectedCard === plan.plan ? "card-selected" : ""
            }`}
            onClick={() => handleCardClick(plan.plan)}
          >
            <div className="title-container">
              <h3 className="plan-title">{plan.title}</h3>
            </div>
            <h2 className="plan-name">
              {plan.plan.includes("fomoPromo") ? "Fomo Promo" : "Hype Train"}
              {plan.plan.includes("Now") ? " Now" : ""}
            </h2>
            <p className="plan-price">{plan.price}</p>
            <div className="divider"></div>
            <ul className="plan-details">
              <li className="plan-detail-item">
                <span className="check-mark"></span> 1 Location
              </li>
              <li className="plan-detail-item">
                <span className="check-mark"></span> {plan.influencers}
              </li>
              <li className="plan-detail-item">
                <span className="check-mark"></span> {plan.reach} Average Local
                Reach
              </li>
              <li className="plan-detail-item">
                <span className="check-mark"></span> Fraud & Imposters
                Protection
              </li>
            </ul>
          </div>
        ))}
      </div>
      <div className="buttons">
        <Button
          type="primary"
          size="large"
          className="black-hover"
          disabled={!selectedCard && selectedCard !== 0}
          onClick={onClickPayNow}
          loading={loading}
        >
          Start Now
        </Button>
      </div>
    </div>
  );
};

export default Pricing;
