import { Outlet, useLocation } from "react-router-dom";
import "./App.scss";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import {
  getBusinessInfo,
  getBusinessSubscription,
  getBusinessess,
} from "redux-context/business";
import { autenticado } from "helpers/auth-functions";
import { getLoggedUserInfo } from "redux-context/user";
import { getPendingCollabsCount } from "redux-context/collabs";
import * as Sentry from "@sentry/react";

// import * as rdd from "react-device-detect";

// (rdd as any).isMobile = true;
// (rdd as any).isDesktop = false;

if (process.env.NODE_ENV === "production") {
  Sentry.init({
    dsn: "https://a3c5c8d7b3b94dfc2ac991c01fd19fc2@o4506367764332544.ingest.us.sentry.io/4508415122472961",
    integrations: [
      Sentry.browserTracingIntegration(),
      Sentry.replayIntegration(),
    ],
    // Tracing
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
    // tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  });
}

function App() {
  const dispatch = useDispatch<any>();
  const location = useLocation();
  const { loading } = useSelector((state: any) => state.user);

  useEffect(() => {
    if (autenticado() && loading) {
      dispatch(getLoggedUserInfo());
    }

    if (!loading) {
      dispatch(getBusinessess());
      dispatch(getBusinessInfo());
      dispatch(getPendingCollabsCount());
    }
  }, [dispatch, loading]);

  useEffect(() => {
    dispatch(getBusinessSubscription());
    dispatch(getPendingCollabsCount());
  }, [location, dispatch]);

  return (
    <>
      <Outlet />
    </>
  );
}

export default App;
