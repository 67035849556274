import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { LoggedUser } from "interfaces/loggedUser";
import { getUserLoggedInfo } from "services/SauceService";

export const getLoggedUserInfo = createAsyncThunk(
  "user/getLoggedUserInfo",
  async () => {
    const userInfo: any = await getUserLoggedInfo();
    return userInfo;
  }
);

export type UserState = {
  user: {
    loading: boolean;
    loggedUser: LoggedUser;
  };
};

export const userSlice: any = createSlice({
  name: "user",
  initialState: {
    loading: true,
    loggedUser: {},
  },
  reducers: {
    setBusiness: (state, action) => {
      state.loggedUser = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getLoggedUserInfo.fulfilled, (state, action) => {
      if (action.payload) {
        state.loggedUser = action.payload;
        const business = localStorage.getItem("businessId");
        if (!business) {
          localStorage.setItem("businessId", action.payload.defaultBusinessId);
        }
        // localStorage.setItem("businessId", action.payload.defaultBusinessId);
      }
      state.loading = false;
    });

    builder.addCase(getLoggedUserInfo.rejected, (state) => {
      state.loading = false;
    });
  },
});

export default userSlice.reducer;
