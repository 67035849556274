import {
  Avatar,
  Button,
  Card,
  Col,
  Divider,
  Row,
  Segmented,
  Spin,
  Typography,
} from "antd";
import { Collab } from "interfaces/collab";
import { useLoaderData, useNavigate, useNavigation } from "react-router-dom";
import { ReactComponent as EditIcon } from "assets/images/edit-icon.svg";
import { ReactComponent as CalendarIcon } from "assets/images/calendar-icon.svg";
import { ReactComponent as ReportIcon } from "assets/images/report-up-icon.svg";
import { ReactComponent as CollabApplicationEmpty } from "assets/images/collab-application-empty.svg";
import { QuestionCircleOutlined } from "@ant-design/icons";
import dayjs from "dayjs";
import { useMemo, useState } from "react";
import InfluencerCard from "pages/influencers/InfluencerCard";
import InfluencerModal from "components/influencer-modal";
import { random } from "lodash";
import { isMobile } from "react-device-detect";
import "../influencers/Influencers.scss";

const genders: any = (gender: string) => {
  return {
    allGenders: "All genders",
    male: "Male",
    female: "Female",
    nonBinary: "Non binary",
  }[gender];
};

enum InfluencerStatus {
  ACCEPTED = "Accepted",
  PENDING = "Pending",
  INVITED = "Invited",
  REJECTED = "Rejected",
  EXPIRED = "Expired",
}

export const CollabAvailability: React.FC<{ availability: any }> = ({
  availability,
}) => {
  const dayFirstLetter = availability.dayOfWeek.charAt(0).toUpperCase();
  const open = availability.open !== false;
  return (
    <Col xs={24}>
      <div style={{ display: "flex", alignItems: "center" }}>
        <Avatar
          style={{
            backgroundColor: open ? "#1D2023" : "#fff",
            color: open ? "#fff" : "#999",
            border: "1px solid #E6E6E6",
            marginRight: 16,
          }}
        >
          {dayFirstLetter}
        </Avatar>
        {open ? (
          <div
            style={{
              display: "flex",

              justifyContent: "space-between",
              gap: 4,
            }}
          >
            <span>{availability.startTime}</span>
            <span>-</span>
            <span>{availability.endTime}</span>
          </div>
        ) : (
          <span style={{ color: "#999" }}>Closed</span>
        )}
      </div>
    </Col>
  );
};

const EmptyList: React.FC<any> = (props) => {
  const { text } = props;
  return (
    <Col xs={24}>
      <Card>
        <Row justify="center">
          <Col md={12} xs={24} style={{ textAlign: "center" }}>
            <Row justify="center">
              <CollabApplicationEmpty />
            </Row>
            <Typography.Title level={3}>No applications yet</Typography.Title>
            <Typography.Text>{text}</Typography.Text>
          </Col>
        </Row>
      </Card>
    </Col>
  );
};

interface CollabDetailParams {
  collab: Collab;
  accepted: Collab[];
  pending: Collab[];
  invited: Collab[];
  rejected: Collab[];
  expired: Collab[];
  error: any;
}

const defaultProps = {
  accepted: [],
  pending: [],
  invited: [],
  rejected: [],
};

const CollabDetail: React.FC = () => {
  const { collab, accepted, pending, invited, rejected, expired } =
    (useLoaderData() as CollabDetailParams) || {
      ...defaultProps,
    };

  const navigation = useNavigation();
  const loading = navigation.state === "loading";
  const [showMore, setShowMore] = useState<boolean>(false);
  const navigate = useNavigate();
  const [selectedStatus, setSelectedStatus] = useState<string>(
    pending.length > 0 ? InfluencerStatus.PENDING : InfluencerStatus.INVITED
  );
  const [selectedFoodie, setSelectedFoodie] = useState<string>("");
  const [openFoodieModal, setOpenFoodieModal] = useState<boolean>(false);

  const onClickFoodie = (foodieId: string) => {
    if (isMobile) return;
    if (foodieId) {
      setSelectedFoodie(foodieId);
      setOpenFoodieModal(true);
    }
  };

  const collabList = useMemo(() => {
    switch (selectedStatus) {
      case InfluencerStatus.ACCEPTED:
        return accepted;
      case InfluencerStatus.PENDING:
        return pending;
      case InfluencerStatus.INVITED:
        return invited;
      case InfluencerStatus.REJECTED:
        return rejected;
      case InfluencerStatus.EXPIRED:
        return expired;
      default:
        return [];
    }
  }, [selectedStatus, accepted, pending, invited, rejected, expired]);

  const onChangeStatus = (status: string) => {
    setSelectedStatus(status);
  };

  const deliverablesTextByProps = () => {
    const deliverablesList = [];
    if (collab?.deliverables?.instagramReel) {
      deliverablesList.push("Instagram Reel");
    }
    if (collab?.deliverables?.instagramStory) {
      deliverablesList.push("Instagram Story");
    }
    if (collab?.deliverables?.tiktokVideo) {
      deliverablesList.push("Tiktok video");
    }
    // if (collab?.deliverables?.googleReview) {
    //   deliverablesList.push("Google review");
    // }
    // if (collab?.deliverables?.yelpReview) {
    //   deliverablesList.push("Yelp review");
    // }
    return deliverablesList.join(" ∘ ");
  };

  const emptyTextByStatus = () => {
    switch (selectedStatus) {
      case InfluencerStatus.ACCEPTED:
        return "As you accept influencers, you will see them on this page";
      case InfluencerStatus.PENDING:
        return "As influencers apply, you'll see them on this page";
      case InfluencerStatus.INVITED:
        return "As we invite influencers, you will see them on this page";
      case InfluencerStatus.REJECTED:
        return "As you reject influencers, you will see them on this page";
      default:
        return null;
    }
  };

  if (loading) return <Spin />;

  return (
    <Row>
      <InfluencerModal
        open={openFoodieModal}
        onCancel={() => setOpenFoodieModal(false)}
        foodieId={selectedFoodie}
      />
      <Col xs={24}>
        <Typography.Title
          style={{ marginBottom: 0, fontSize: isMobile ? 20 : "unset" }}
        >
          {collab?.venue?.name}
        </Typography.Title>
        <Typography.Text
          style={{
            display: "block",
            marginBottom: isMobile ? 12 : 40,
            fontSize: 16,
          }}
        >
          {collab?.venue?.addressDisplay || collab?.venue?.address}
        </Typography.Text>
      </Col>
      <Col xs={24}>
        <Card style={{ padding: 16 }}>
          <Row>
            <Col xs={24}>
              <Row gutter={[20, isMobile ? 8 : 20]}>
                <Col xs={24} md={12}>
                  <Typography.Text>Brief</Typography.Text>
                </Col>
                <Col xs={24} md={12}>
                  <Typography.Text> {collab?.brief}</Typography.Text>
                </Col>
                <Col xs={24} md={12}>
                  <Typography.Text>Start date</Typography.Text>
                </Col>
                <Col xs={24} md={12}>
                  <Typography.Text
                    style={{ display: "flex", alignItems: "center", gap: 2 }}
                  >
                    <CalendarIcon />
                    {dayjs.utc(collab?.startDate || "").format("MMM DD, YYYY")}
                  </Typography.Text>
                </Col>

                <Col xs={24}>
                  <Row gutter={[8, 8]}>
                    <Col xs={24} md={12}>
                      <Button
                        type="primary"
                        onClick={() => setShowMore(!showMore)}
                      >
                        {showMore ? "Hide details" : "Show details"}
                      </Button>
                    </Col>
                    <Col xs={24} md={12}>
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          flexWrap: "wrap",
                          gap: 12,
                        }}
                      >
                        <Button
                          icon={<EditIcon />}
                          onClick={() =>
                            navigate(`/collabs/${collab.collabId}/edit`)
                          }
                          style={{
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          Edit details
                        </Button>
                        <Button
                          icon={<ReportIcon />}
                          onClick={() =>
                            navigate(`/report?collabId=${collab.collabId}`)
                          }
                          style={{
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          Report
                        </Button>
                      </div>
                    </Col>
                  </Row>
                </Col>

                {showMore && (
                  <>
                    <Divider />
                    <Col xs={24} md={12}>
                      <Typography.Text>Basic influencers</Typography.Text>
                    </Col>
                    <Col xs={24} md={12}>
                      {collab?.basicFoodiesNeeded}
                    </Col>
                    <Col xs={24} md={12}>
                      <Typography.Text>Premium influencers</Typography.Text>
                    </Col>
                    <Col xs={24} md={12}>
                      {collab?.premiumFoodiesNeeded}
                    </Col>
                    <Col xs={24} md={12}>
                      <Typography.Text>Period</Typography.Text>
                    </Col>
                    <Col xs={24} md={12}>
                      {dayjs.utc(collab?.startDate || "").format("MMM DD")} -{" "}
                      {dayjs.utc(collab?.endDate || "").format("MMM DD")}
                    </Col>
                    <Col xs={24} md={12}>
                      <Typography.Text>Visiting hours</Typography.Text>
                    </Col>
                    <Col xs={24} md={12}>
                      <Row gutter={[14, 14]}>
                        {collab?.bookingAvailability?.map((availability) => (
                          <CollabAvailability availability={availability} />
                        ))}
                      </Row>
                    </Col>
                    <Divider />
                    <Col xs={24} md={12}>
                      <Typography.Text>
                        Mustard Team Accepting On Your Behalf
                      </Typography.Text>
                    </Col>
                    <Col xs={24} md={12}>
                      {collab?.deliveryTeamApproval ? "Yes" : "No"}
                    </Col>

                    <Col xs={24} md={12}>
                      <Typography.Text>Deliverables</Typography.Text>
                    </Col>
                    <Col xs={24} md={12}>
                      {deliverablesTextByProps()}
                    </Col>

                    <Col xs={24} md={12}>
                      <Typography.Text>Influencer gender</Typography.Text>
                    </Col>
                    <Col xs={24} md={12}>
                      <Typography.Text>
                        {genders(collab?.gender)}
                      </Typography.Text>
                    </Col>
                    <Col xs={24} md={12}>
                      <Typography.Text>Min followers</Typography.Text>
                    </Col>
                    <Col xs={24} md={12}>
                      {collab?.minFollowersAmount} for premium influencers
                    </Col>
                    <Col xs={24} md={12}>
                      <Typography.Text>
                        Max distance <QuestionCircleOutlined />
                      </Typography.Text>
                    </Col>
                    <Col xs={24} md={12}>
                      {collab?.maxMilesDistance} miles
                    </Col>
                    <Col xs={24} md={12}>
                      <Typography.Text>
                        Influencer that only cover
                      </Typography.Text>
                    </Col>
                    <Col xs={24} md={12}>
                      <Typography.Text>
                        {collab?.categories?.join(" ∘ ")}
                      </Typography.Text>
                    </Col>

                    <Divider />
                    <Col xs={24} md={12}>
                      <Typography.Text>Food compensation</Typography.Text>
                    </Col>
                    <Col xs={24} md={12}>
                      {" "}
                      ${collab?.foodComp}
                    </Col>
                    <Col xs={24} md={12}>
                      <Typography.Text>Guest</Typography.Text>
                    </Col>
                    <Col xs={24} md={12}>
                      {collab?.compensationFood}
                    </Col>
                    <Col xs={24}>
                      <Row gutter={[8, 8]}>
                        <Col xs={24} md={12}>
                          <Button
                            type="primary"
                            onClick={() => setShowMore(!showMore)}
                          >
                            {showMore ? "Hide details" : "Show details"}
                          </Button>
                        </Col>
                        <Col xs={24} md={12}>
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              gap: 12,
                            }}
                          >
                            <Button
                              icon={<EditIcon />}
                              onClick={() =>
                                navigate(`/collabs/${collab.collabId}/edit`)
                              }
                              style={{
                                display: "flex",
                                alignItems: "center",
                              }}
                            >
                              Edit details
                            </Button>
                            <Button
                              icon={<ReportIcon />}
                              onClick={() =>
                                `/report?collabId=${collab.collabId}`
                              }
                              style={{
                                display: "flex",
                                alignItems: "center",
                              }}
                            >
                              Report
                            </Button>
                          </div>
                        </Col>
                      </Row>
                    </Col>
                  </>
                )}
              </Row>
            </Col>
          </Row>
        </Card>
      </Col>
      <Col xs={24}>
        <Typography.Title style={{ fontSize: isMobile ? 20 : "unset" }}>
          Applications
        </Typography.Title>
      </Col>
      <Col xs={24}>
        <Segmented
          options={[
            { value: "Pending", label: `Pending (${pending.length})` },
            { value: "Accepted", label: `Accepted (${accepted.length})` },
            // { value: "Invited", label: `Invited (${invited.length})` },
            { value: "Rejected", label: `Rejected (${rejected.length})` },
            { value: "Expired", label: `Expired (${expired.length})` },
          ]}
          value={selectedStatus}
          onChange={onChangeStatus}
          style={{
            marginBottom: isMobile ? 12 : 32,
            width: isMobile ? "100%" : "unset",
          }}
          className="segmented-mobile"
        />
      </Col>
      <Col xs={24}>
        <Row
          className="influencers"
          justify={isMobile ? "center" : "start"}
          style={{ minHeight: 600, gap: 16 }}
        >
          {collabList.length > 0 ? (
            collabList.map((influencer: any, index: number) => (
              <InfluencerCard
                hideLocation
                influencer={influencer}
                onInfluencerClick={() => onClickFoodie(influencer.foodieId)}
                key={`${selectedStatus}_APPLICATIONS_${influencer.username}_${
                  influencer.collabId
                }_${influencer?.venue?.uuid}_${random(3)}_${
                  influencer.bookingId
                }_${index}`}
              />
            ))
          ) : (
            <EmptyList text={emptyTextByStatus()} />
          )}
        </Row>
      </Col>
    </Row>
  );
};

export default CollabDetail;
