import {
  Button,
  Card,
  Checkbox,
  Col,
  DatePicker,
  Form,
  Input,
  InputNumber,
  Radio,
  Row,
  Segmented,
  Select,
  Slider,
  // Tag,
  Typography,
} from "antd";
import {
  PlusCircleOutlined,
  // PlusOutlined,
  QuestionCircleOutlined,
} from "@ant-design/icons";
import { useEffect, useMemo, useState } from "react";
import { useLoaderData, useNavigate } from "react-router-dom";
import ModalVenue from "./ModalVenue";
import { useDispatch, useSelector } from "react-redux";
import { isMobile } from "react-device-detect";
import WeekHoursField from "./WeekHoursField";
import { BusinessState, getBusinessInfo } from "redux-context/business";
import {
  createBusinessCollab,
  getFoodieCategories,
} from "services/SauceService";
import bookingAvailability from "./weekHoursDefault.json";
import { ReactComponent as CollabSuccess } from "assets/images/collab-success.svg";
// import MustardIcon from "assets/images/icon.png";
import dayjs from "dayjs";
import { Collab } from "interfaces/collab";
import Joyride, { ACTIONS, EVENTS, STATUS, Step } from "react-joyride";
import "./Collab.scss";
import { CollabsState } from "redux-context/collabs";

enum FormStep {
  MainInfo = 0,
  Brief = 1,
  InfluencerInfo = 2,
  Compensation = 3,
  Promotion = 4,
  Success = 5,
}

const stepsByFormStep: {
  [key: number]: Step[];
} = {
  [FormStep.MainInfo]: [
    // {
    //   target: ".collab-venue",
    //   content: "This is my first Step",
    //   placement: "bottom-end",
    //   disableBeacon: true,
    // },
    // {
    //   target: ".collab-premium-influencers",
    //   content: "This is my second Step",
    //   placement: "bottom-end",
    // },
    // {
    //   target: ".collab-basic-influencers",
    //   content: "This is my third Step",
    //   placement: "bottom-end",
    // },
    // {
    //   target: ".collab-start-date",
    //   content: "This is my fourth Step",
    //   placement: "bottom-end",
    // },
    // {
    //   target: ".collab-duration",
    //   content: "This is my fifth Step",
    //   placement: "bottom-end",
    // },
    // {
    //   target: ".booking-availability",
    //   content: "This is my sixth Step",
    //   placement: "bottom-end",
    // },
    // {
    //   target: ".collab-slot-size",
    //   content: "This is my seventh Step",
    //   placement: "bottom-end",
    // },
  ],
  [FormStep.Brief]: [
    // {
    //   target: ".collab-brief",
    //   content: "This is my eighth Step",
    //   placement: "bottom-end",
    // },
    // {
    //   target: ".collab-focus-points",
    //   content: "This is my ninth Step",
    //   placement: "bottom-end",
    // },
  ],
  [FormStep.InfluencerInfo]: [],
  [FormStep.Compensation]: [],
  [FormStep.Promotion]: [],
};

const CollabEdit: React.FC = () => {
  const { collab } = (useLoaderData() as { collab: Collab }) || {};
  const [step, setStep] = useState<FormStep>(FormStep.MainInfo);
  const [showModalVenue, setshowModalVenue] = useState<boolean>(false);
  const [categories, setCategories] = useState<any[]>([]);
  const [stepIndex, setStepIndex] = useState<number>(0);
  const [runTour, setRunTour] = useState<boolean>(false);
  const [venues, setVenues] = useState<any[]>([]);
  const { business, subscription } = useSelector(
    (state: BusinessState) => state.business
  );
  const { numbers } = useSelector((state: CollabsState) => state.collabs);
  const showOnboarding = useMemo(() => {
    return numbers.reviewed === 0 && numbers.totalCollabs === 0;
  }, [numbers]);

  const [form] = Form.useForm();
  const dispatch = useDispatch<any>();
  const navigate = useNavigate();
  const promotionAvailable = business?.promotionEnabled;
  const canAddVenue = collab
    ? false
    : subscription?.locationsAllowed > subscription?.locationsUsage;

  const saveTextButton =
    collab ||
    (promotionAvailable
      ? step === FormStep.Promotion
      : step === FormStep.Compensation)
      ? "Save"
      : step === FormStep.Success
      ? "Done"
      : "Continue";

  const onSelectVenue = (_: string, option: any) => {
    if (option.venueWorkingHours && option.venueWorkingHours.length > 0)
      form.setFieldValue("bookingAvailability", option.venueWorkingHours);
    else form.setFieldValue("bookingAvailability", bookingAvailability);
  };

  const onSaveVenue = async () => {
    dispatch(getBusinessInfo());
    setshowModalVenue(false);
  };

  const onClickContinue = async () => {
    if (collab) {
      const values = form.getFieldsValue(true);
      const params = {
        ...values,
        businessId: business._id,
      };

      const response: any = createBusinessCollab(params);
      if (response) {
        navigate("/collabs");
      }

      return;
    }

    const saveCollab = async () => {
      const values = form.getFieldsValue(true);
      const params = {
        ...values,
        startDate: values.startDate.format("YYYY-MM-DD"),
        businessId: business._id,
        promotionEnabled: promotionAvailable,
      };

      const response: any = createBusinessCollab(params);
      if (response) {
        setStep(FormStep.Success);
      }
      return;
    };

    if (step === FormStep.Compensation) {
      if (promotionAvailable) {
        setStep(FormStep.Promotion);
        return;
      }
      saveCollab();
      return;
    }

    if (step === FormStep.Promotion) {
      saveCollab();
      return;
    }

    setStep(step + 1);
  };

  const onClickBack = () => {
    if (collab) {
      navigate("/collabs");
      return;
    }

    setStep(step - 1);
  };

  const checkInfluencerMood = (
    foodComp: number,
    averageCheck: number,
    guestAllowed: boolean
  ) => {
    if (foodComp === 0 || averageCheck === 0) return "";
    if (foodComp >= 2 * averageCheck && guestAllowed) {
      return "😎 Influencers will be happy to apply";
    }
    if (foodComp >= 2 * averageCheck && !guestAllowed) {
      return "😎 Influencers will be interested";
    }
    if (
      foodComp <= 2 * averageCheck &&
      foodComp >= averageCheck &&
      guestAllowed
    ) {
      return "Influencers might apply";
    }
    if (
      foodComp <= 2 * averageCheck &&
      foodComp >= averageCheck &&
      !guestAllowed
    ) {
      return "Influencers might be ok";
    }
    if (foodComp < averageCheck && guestAllowed) {
      return "Influencers will rarely apply";
    }
    if (foodComp < averageCheck && !guestAllowed) {
      return "Influencers will not be attracted to this offer";
    }
    return "";
  };

  const handleJoyrideCallback = (data: any) => {
    const { action, index, status, type } = data;
    if ([STATUS.FINISHED, STATUS.SKIPPED].includes(status)) {
      setRunTour(false);
    } else if ([EVENTS.STEP_AFTER, EVENTS.TARGET_NOT_FOUND].includes(type)) {
      setStepIndex(index + (action === ACTIONS.PREV ? -1 : 1));
    }
  };

  useEffect(() => {
    setVenues(
      business?.venues?.map((item: any) => ({
        ...item,
        value: item.uuid,
        label: `${item.name} - ${item.address}`,
      })) || []
    );
  }, [business]);

  useEffect(() => {
    const fetchCategories = async () => {
      const response: any = await getFoodieCategories();
      setCategories(
        response.map((item: any) => ({ value: item, label: item }))
      );
    };
    fetchCategories();
  }, []);

  useEffect(() => {
    setStepIndex(0);
    setRunTour(true);
  }, [step]);

  useEffect(() => {
    form.resetFields();
  }, [form, venues]);

  return (
    <Row className="collab">
      {showOnboarding && (
        <Joyride
          continuous
          callback={handleJoyrideCallback}
          stepIndex={stepIndex}
          run={runTour}
          steps={stepsByFormStep[step]}
          spotlightClicks
          // beaconComponent={(props, ref) => {
          //   return (
          //     <div ref={(refLocal) => (refLocal = ref)} {...props}>
          //       <img src={MustardIcon} alt="Mustard" className="beacon-image" />
          //     </div>
          //   );
          // }}
          styles={{
            options: {
              primaryColor: "#ffc261",
              textColor: "#1D2023",
              zIndex: 1000,
            },
          }}
        />
      )}

      <Col xs={24}>
        <Typography.Title>
          {collab ? "Edit collab" : "Create collab"}
        </Typography.Title>
      </Col>
      <Col xs={24}>
        <Segmented
          size="large"
          options={[
            { value: FormStep.MainInfo, label: `Collab details` },
            { value: FormStep.Brief, label: `Brief & Instructions` },
            {
              value: FormStep.InfluencerInfo,
              label: `Influencer Requirements`,
            },
            { value: FormStep.Compensation, label: `Compensation` },
            ...(promotionAvailable
              ? [{ value: FormStep.Promotion, label: `Promotion` }]
              : []),
          ]}
          onChange={(value) => {
            setStep(value as FormStep);
          }}
          style={{
            borderRadius: "8px 8px 0 0 ",
            display: collab ? "" : "none",
            width: isMobile ? "100%" : "unset",
          }}
          hidden={!!collab}
          className="collab-segmented segmented-mobile"
        />
        <Card
          style={{
            padding: isMobile ? 0 : "48px 120px",
            borderRadius: "0 0 8px 8px",
          }}
          bordered={false}
        >
          {step === FormStep.Success ? (
            <Row justify="center">
              <Col md={12} xs={24} style={{ textAlign: "center" }}>
                <Row justify="center">
                  <CollabSuccess />
                </Row>
                <Typography.Title level={3}>
                  Collab Successfully Created
                </Typography.Title>
                <Typography.Text>
                  Influencer responses to this collab will appear in the
                  Influencers section
                </Typography.Text>
                <Row justify="center" style={{ marginTop: 42 }}>
                  <Button type="primary" onClick={() => navigate("/collabs")}>
                    Open collab list
                  </Button>
                </Row>
              </Col>
            </Row>
          ) : (
            <>
              <Row style={{ display: collab ? "none" : "flex" }}>
                <Col xs={16}>
                  <Typography.Title level={3}>
                    {step === FormStep.MainInfo && "Collab details"}
                    {step === FormStep.Brief && "Brief & Instructions"}
                    {step === FormStep.InfluencerInfo &&
                      "Influencer Requirements"}
                    {step === FormStep.Compensation && "Compensation"}
                    {step === FormStep.Promotion && "Promotion"}
                  </Typography.Title>
                </Col>
                <Col
                  xs={8}
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "flex-end",
                    color: "#666666",
                  }}
                >
                  Step {step + 1} / {promotionAvailable ? 5 : 4}
                </Col>
              </Row>
              <Row style={{ marginTop: 32 }}>
                <Col xs={24}>
                  <ModalVenue
                    open={showModalVenue}
                    onCancel={() => setshowModalVenue(false)}
                    onSave={onSaveVenue}
                  />
                  <Form
                    form={form}
                    layout="vertical"
                    name="collabFrom"
                    requiredMark="optional"
                    initialValues={
                      collab || {
                        venue: {
                          uuid: venues.length > 0 ? venues[0].value : "",
                        },
                        premiumFoodiesNeeded: 0,
                        basicFoodiesNeeded: 1,
                        startDate: dayjs().add(1, "day"),
                        totalMonthsNeeded: 1,
                        bookingAvailability,
                        // minFollowersAmount: 5000,
                        slotSizeMinutes: 60,
                        maxMilesDistance: 30,
                        gender: "allGenders",
                        deliveryTeamApproval: true,
                        averageCheckAmount: 60,
                        foodComp: 120,
                        guestAllowed: true,
                        deliverables: {
                          instagramReel: true,
                          instagramStory: true,
                          tiktokVideo: true,
                        },
                      }
                    }
                  >
                    {step === FormStep.MainInfo && (
                      <Row>
                        <Col xs={24}>
                          <Form.Item
                            label="Choose location"
                            name={["venue", "uuid"]}
                            rules={[{ required: true }]}
                            className="collab-venue"
                            style={{ width: "50%" }}
                          >
                            <Select
                              placeholder="select"
                              options={venues}
                              onSelect={onSelectVenue}
                              disabled={!!collab}
                            />
                          </Form.Item>
                        </Col>
                        {canAddVenue && (
                          <Col xs={24} style={{ marginBottom: 24 }}>
                            <span
                              style={{ color: "#1D2023", cursor: "pointer" }}
                              onClick={() => setshowModalVenue(true)}
                            >
                              <PlusCircleOutlined style={{ marginRight: 4 }} />
                              Add another location
                            </span>
                          </Col>
                        )}
                        <Col xs={24}>
                          <Form.Item
                            label="Premium influencers"
                            name="premiumFoodiesNeeded"
                            extra={`Your plan provides ${
                              business?.termPlanPremiumFoodies || 0
                            } premium Influencers per month 💎`}
                            rules={[{ required: true }]}
                            tooltip={{
                              title:
                                "Influencers with a total following of over 10K.",
                              icon: <QuestionCircleOutlined />,
                            }}
                            style={{ width: "50%" }}
                            className="collab-premium-influencers"
                          >
                            <Select
                              options={Array.from(
                                {
                                  length:
                                    business?.termPlanPremiumFoodies + 1 || 0,
                                },
                                (_, index) => ({
                                  value: index,
                                  label: `${index}`,
                                })
                              )}
                            />
                          </Form.Item>
                        </Col>
                        <Col xs={24}>
                          <Form.Item
                            label="Basic influencers"
                            name="basicFoodiesNeeded"
                            extra={`Your plan provides ${
                              business?.termPlanBasicFoodies || 0
                            } basic Influencers per month`}
                            rules={[{ required: true }]}
                            tooltip={{
                              title:
                                "Influencers with a total following of less than 10K.",
                              icon: <QuestionCircleOutlined />,
                            }}
                            style={{ width: "50%" }}
                            className="collab-basic-influencers"
                          >
                            <Select
                              options={Array.from(
                                {
                                  length:
                                    business?.termPlanBasicFoodies + 1 || 0,
                                },
                                (_, index) => ({
                                  value: index,
                                  label: `${index}`,
                                })
                              )}
                            />
                          </Form.Item>
                        </Col>

                        <Col xs={24}>
                          <Form.Item
                            label="Start date"
                            name="startDate"
                            extra="When the collab becomes available for Influencers"
                            getValueProps={(i) => {
                              if (dayjs(i, "YYYY-MM-DD").isValid())
                                return { value: dayjs(i, "YYYY-MM-DD") };
                              return {};
                            }}
                            rules={[{ required: true }]}
                            style={{ width: "50%" }}
                            className="collab-start-date"
                          >
                            <DatePicker style={{ width: "100%" }} />
                          </Form.Item>
                        </Col>
                        <Col
                          xs={24}
                          style={{ display: collab ? "none" : "flex" }}
                        >
                          <Form.Item
                            label="Duration"
                            name="totalMonthsNeeded"
                            extra="This will create one collab for each month. You can specify instructions for a specific month in Collabs."
                            rules={[{ required: true }]}
                            className="collab-duration"
                            style={{ width: "50%" }}
                          >
                            <Select
                              options={Array.from(
                                { length: business?.amountMonths || 0 },
                                (_, index) => ({
                                  value: index + 1,
                                  label: `${index + 1}`,
                                })
                              )}
                            />
                          </Form.Item>
                        </Col>
                        <Col
                          xs={24}
                          md={12}
                          style={{
                            width: "100%",
                            minWidth: isMobile ? "unset" : 425,
                          }}
                          className="booking-availability"
                        >
                          <Typography.Text
                            style={{ paddingBottom: 8, display: "block" }}
                          >
                            Visiting Hours
                          </Typography.Text>
                          <div>
                            <WeekHoursField
                              name="bookingAvailability"
                              form={form}
                            />
                          </div>
                        </Col>
                        <Col xs={24}>
                          <Form.Item
                            label="Reservation duration (in minutes)"
                            name="slotSizeMinutes"
                            rules={[{ required: true }]}
                            className="collab-slot-size"
                            style={{ width: isMobile ? "100%" : "50%" }}
                          >
                            <InputNumber />
                          </Form.Item>
                        </Col>
                      </Row>
                    )}
                    {step === FormStep.Brief && (
                      <Row>
                        <Col xs={24}>
                          <Form.Item
                            label="Brief"
                            name="brief"
                            extra="Influencer see the Brief before they apply for a collab"
                            tooltip={{
                              title:
                                "Influencers see collab briefs when browsing various collab offers in the area. Make sure to clearly explain why they should visit your restaurant. For example, mention if you have a beautiful location, use locally sourced ingredients, and what your general expectations are for them.",
                              icon: <QuestionCircleOutlined />,
                            }}
                            className="collab-brief"
                          >
                            <Input.TextArea rows={5} />
                          </Form.Item>
                        </Col>
                        <Col xs={24}>
                          <Form.Item
                            label="Collab Instructions"
                            name="focusPoints"
                            extra="Specify highlights, including dishes or events. Instructions are sent to influencers after they are approved into the collab."
                            tooltip={{
                              title:
                                "Influencers will receive these instructions after they have been admitted into the collab.Describe what you would like influencers to focus on. For example, mention a dish you want to highlight or a specific offer they should promote. ",
                              icon: <QuestionCircleOutlined />,
                            }}
                            className="collab-focus-points"
                          >
                            <Input.TextArea rows={5} />
                          </Form.Item>
                        </Col>
                      </Row>
                    )}
                    {step === FormStep.InfluencerInfo && (
                      <>
                        <Row
                          style={{
                            flexDirection: "column",
                            marginBottom: 32,
                          }}
                        >
                          <Typography.Text
                            style={{ paddingBottom: 8, display: "block" }}
                          >
                            Mustard AI accepting influencers on your behalf
                            (Recommended)
                          </Typography.Text>
                          <Form.Item noStyle name="deliveryTeamApproval">
                            <Radio.Group disabled={!!collab}>
                              <Radio value={true}>Yes</Radio>
                              <Radio value={false}>No</Radio>
                            </Radio.Group>
                          </Form.Item>
                        </Row>
                        <Row
                          style={{
                            flexDirection: "column",
                            marginBottom: 32,
                          }}
                        >
                          <Typography.Text
                            style={{ paddingBottom: 8, display: "block" }}
                          >
                            Deliverables
                          </Typography.Text>
                          <Form.Item
                            noStyle
                            name={["deliverables", "instagramReel"]}
                            valuePropName="checked"
                          >
                            <Checkbox>Instagram Reel</Checkbox>
                          </Form.Item>
                          <Form.Item
                            noStyle
                            name={["deliverables", "instagramStory"]}
                            valuePropName="checked"
                          >
                            <Checkbox>Instagram Story</Checkbox>
                          </Form.Item>
                          <Form.Item
                            noStyle
                            name={["deliverables", "tiktokVideo"]}
                            valuePropName="checked"
                          >
                            <Checkbox>TikTok Video</Checkbox>
                          </Form.Item>
                          {/* <Form.Item
                            noStyle
                            name={["deliverables", "yelpReview"]}
                            valuePropName="checked"
                          >
                            <Checkbox>Yelp Review (can only suggest)</Checkbox>
                          </Form.Item>
                          <Form.Item
                            noStyle
                            name={["deliverables", "googleReview"]}
                            valuePropName="checked"
                          >
                            <Checkbox>
                              Google Review (can only suggest)
                            </Checkbox>
                          </Form.Item> */}
                        </Row>
                        <Row>
                          <Col xs={24}>
                            <Form.Item
                              label="Influencer gender"
                              name="gender"
                              rules={[{ required: true }]}
                            >
                              <Select
                                options={[
                                  {
                                    value: "allGenders",
                                    label: "All genders",
                                  },
                                  { value: "male", label: "Male" },
                                  { value: "female", label: "Female" },
                                  { value: "nonBinary", label: "Non binary" },
                                ]}
                                style={{ width: "50%" }}
                              />
                            </Form.Item>
                          </Col>
                          {/* <Col xs={24}>
                            <Form.Item
                              label="Minimum followers for premium influencers"
                              name="minFollowersAmount"
                              rules={[{ required: true }]}
                            >
                              <InputNumber
                                style={{ width: "50%" }}
                                defaultValue={5000}
                              />
                            </Form.Item>
                          </Col> */}
                          <Col xs={24}>
                            <Form.Item
                              label="Influencers max distance (miles)"
                              name="maxMilesDistance"
                              tooltip={{
                                title:
                                  "This is the radius in miles within which we search for influencers around your restaurant.",
                                icon: <QuestionCircleOutlined />,
                              }}
                              rules={[{ required: true }]}
                            >
                              <Slider
                                min={5}
                                max={55}
                                tooltip={{
                                  open: false,
                                }}
                                marks={{
                                  5: "0",
                                  10: "10",
                                  15: "15",
                                  20: "20",
                                  30: "30",
                                  40: "40",
                                  50: "50",
                                  55: ">50",
                                }}
                              />
                            </Form.Item>
                          </Col>
                          <Col xs={24}>
                            <Form.Item
                              label="Influencer that only cover"
                              name="categories"
                            >
                              <Select mode="multiple" options={categories} />
                            </Form.Item>
                            {/* <Form.Item
                              shouldUpdate={(prevValues, curValues) =>
                                prevValues.categories !== curValues.categories
                              }
                            >
                              {({ getFieldValue }) => {
                                const localCategories =
                                  getFieldValue("categories") || [];
                                const unselectedCategories = categories.filter(
                                  (item) =>
                                    !localCategories.includes(item.value)
                                );
                                return (
                                  <div
                                    style={{
                                      display: "flex",
                                      flexWrap: "wrap",
                                      gap: 16,
                                    }}
                                  >
                                    {unselectedCategories
                                      .slice(0, 10)
                                      .map((item) => (
                                        <Tag
                                          icon={<PlusOutlined />}
                                          color="default"
                                          onClick={() =>
                                            form.setFieldValue("categories", [
                                              ...localCategories,
                                              item.value,
                                            ])
                                          }
                                          key={`${item.value}_CATEGORY`}
                                          style={{ cursor: "pointer" }}
                                        >
                                          {item.label}
                                        </Tag>
                                      ))}
                                  </div>
                                );
                              }}
                            </Form.Item> */}
                          </Col>
                        </Row>
                      </>
                    )}

                    {step === FormStep.Compensation && (
                      <Row>
                        <Col xs={24} style={{ marginBottom: 24 }}>
                          <Typography.Text>
                            Food compensation indicates the amount of
                            complimentary food for the influencer and one guest
                          </Typography.Text>
                        </Col>
                        <Col xs={24}>
                          <Form.Item
                            label="Your average check size"
                            extra="Average amount spent by a customer at your restaurant"
                            name="averageCheckAmount"
                            rules={[{ required: true }]}
                          >
                            <InputNumber min={0} style={{ width: "50%" }} />
                          </Form.Item>
                        </Col>
                        <Col xs={24}>
                          <Form.Item
                            label="Food compensation"
                            extra="We recommend to provide an allowance up to twice 
                          the average check size, which covers both the influencer and their guest"
                            name="foodComp"
                            rules={[{ required: true }]}
                          >
                            <InputNumber min={0} style={{ width: "50%" }} />
                          </Form.Item>
                        </Col>
                        <Col xs={24} style={{ marginBottom: 32 }}>
                          <Form.Item shouldUpdate noStyle>
                            {({ getFieldValue }) => {
                              const guestAllowed =
                                getFieldValue("guestAllowed");
                              const foodComp = getFieldValue("foodComp");
                              const averageCheck =
                                getFieldValue("averageCheckAmount");

                              return (
                                <span style={{ fontSize: 16 }}>
                                  {checkInfluencerMood(
                                    foodComp,
                                    averageCheck,
                                    guestAllowed
                                  )}
                                </span>
                              );
                            }}
                          </Form.Item>
                        </Col>
                        <Col xs={24} style={{}}>
                          <Form.Item
                            name="guestAllowed"
                            noStyle
                            valuePropName="checked"
                          >
                            <Checkbox>
                              <b>+1 guest allowed</b>{" "}
                              <span
                                style={{
                                  color: "#999999",
                                }}
                              >
                                {" "}
                                (recommended)
                              </span>
                            </Checkbox>
                          </Form.Item>
                        </Col>
                      </Row>
                    )}
                    {step === FormStep.Promotion && (
                      <>
                        <Row>
                          <Col xs={24}>
                            <Form.Item
                              name="promotionOfferSummary"
                              label="Offer summary"
                              tooltip="Provide a brief summary of the offer you want influencers to mention. For example: 'Enjoy a VIP Omakase Experience for only $55.'"
                            >
                              <Input />
                            </Form.Item>
                          </Col>
                          <Col xs={24}>
                            <Form.Item
                              name="promotionSwipeLink"
                              label="Swipe-up link"
                              tooltip="Provide a link that you want influencers to include in their Instagram Story."
                            >
                              <Input />
                            </Form.Item>
                          </Col>
                          <Col xs={24}>
                            <Form.Item
                              name="promotionCaptionLink"
                              label="Link caption"
                              tooltip="Specify what the link should say, for example: 'ORDER NOW'"
                            >
                              <Input />
                            </Form.Item>
                          </Col>
                          <Col xs={24}>
                            <Form.Item
                              name="promotionActionDm"
                              label="Action DM"
                              tooltip="Instagram does not allow embedding links in Reels, but you can ask influencers to direct message (DM) your brand instead. For example, you can ask them to say, 'DM 'VIP' to @restaurantname to receive a free drink.'"
                            >
                              <Input />
                            </Form.Item>
                          </Col>
                          <Col xs={24}>
                            <Form.Item
                              name="promotionResponseDm"
                              label="DM's response"
                              tooltip="Specify the text that should be sent in response to the Action DM. For example, you could say, 'We are super excited to have you! Please mention that you are an Instagram VIP to our staff during your visit to get a free drink.'"
                            >
                              <Input />
                            </Form.Item>
                          </Col>
                        </Row>
                      </>
                    )}
                  </Form>
                </Col>
              </Row>
              <Row style={{ gap: 32, marginTop: 32 }}>
                <Button type="primary" onClick={onClickContinue}>
                  {saveTextButton}
                </Button>
                {(collab || step !== FormStep.MainInfo) && (
                  <Button onClick={onClickBack}>
                    {collab ? "Cancel" : "Back"}
                  </Button>
                )}
              </Row>
            </>
          )}
        </Card>
      </Col>
    </Row>
  );
};

export default CollabEdit;
