import { Button, Card, Col, Row, Typography } from "antd";
import { useNavigate } from "react-router-dom";
import CreateCollabOonboard from "assets/images/create-collab-onboard.png";
import SelectInfluencerOnboard from "assets/images/select-influencer-onboard.png";
import WaitForApplicationOnboard from "assets/images/wait-application-onboard.png";
import { ReactComponent as CheckIcon } from "assets/images/check-icon.svg";
import { ReactComponent as ClockIcon } from "assets/images/clock-icon.svg";
import { useMemo } from "react";
import { useSelector } from "react-redux";
import { CollabsState } from "redux-context/collabs";

export enum EmptyHomeStep {
  CREATE_COLLAB = "CREATE_COLLAB",
  WAIT_APPLICATIONS = "WAIT_APPLICATIONS",
  SELECT_INFLUENCERS = "SELECT_INFLUENCERS",
}

const Done = () => (
  <div className="box-with-svg">
    <CheckIcon /> Done
  </div>
);

const EmptyHomeCard: React.FC = () => {
  const navigate = useNavigate();
  const { numbers } = useSelector((state: CollabsState) => state.collabs);

  const step = useMemo(() => {
    if (numbers?.totalCollabs === 0) return EmptyHomeStep.CREATE_COLLAB;
    if (numbers?.totalApplications === 0)
      return EmptyHomeStep.WAIT_APPLICATIONS;
    return EmptyHomeStep.SELECT_INFLUENCERS;
  }, [numbers]);

  const onClickCreateCollab = () => {
    navigate("/collabs/new");
  };

  const onClickSelectInfluencer = () => {
    navigate("/influencers");
  };

  const cardWaidApplicationClass =
    step === EmptyHomeStep.WAIT_APPLICATIONS
      ? "current"
      : step === EmptyHomeStep.CREATE_COLLAB
      ? "upcoming"
      : "";

  const cardSelectInfluencerClass =
    step === EmptyHomeStep.SELECT_INFLUENCERS ? "current" : "upcoming";

  return (
    <div className="onboarding-card">
      <Card style={{ padding: 40 }}>
        <Row justify="center">
          <Col xs={24} style={{ textAlign: "center" }}>
            <Typography.Title level={4} style={{ marginTop: 0 }}>
              Create your first collab
            </Typography.Title>
            <Typography.Text>
              After creating a collab, you can view responses from interested
              influencers
            </Typography.Text>
            <div className="steps-container">
              <div
                className={`step-card ${
                  step === EmptyHomeStep.CREATE_COLLAB ? "current" : ""
                } `}
              >
                <Typography.Title level={5} style={{ width: 150 }}>
                  Create Your First Collab
                </Typography.Title>
                <img
                  className="main-image"
                  src={CreateCollabOonboard}
                  alt="step1"
                />
                {step === EmptyHomeStep.CREATE_COLLAB ? (
                  <Button type="primary" onClick={onClickCreateCollab}>
                    Create Collab
                  </Button>
                ) : (
                  <Done />
                )}
              </div>

              <div className={`step-card ${cardWaidApplicationClass}`}>
                <Typography.Title level={5} style={{ width: 150 }}>
                  Wait for Applications
                </Typography.Title>
                <img
                  className="main-image"
                  src={SelectInfluencerOnboard}
                  alt="step1"
                />
                {step === EmptyHomeStep.CREATE_COLLAB ? (
                  <span className="span-step">2nd Step</span>
                ) : step === EmptyHomeStep.WAIT_APPLICATIONS ? (
                  <div className="box-with-svg">
                    <ClockIcon />
                    In Progress
                  </div>
                ) : (
                  <Done />
                )}
              </div>

              <div className={`step-card ${cardSelectInfluencerClass}`}>
                <Typography.Title level={5} style={{ width: 150 }}>
                  Accept Influencers
                </Typography.Title>
                <img
                  className="main-image"
                  src={WaitForApplicationOnboard}
                  alt="step1"
                />
                {step === EmptyHomeStep.SELECT_INFLUENCERS ? (
                  <Button type="primary" onClick={onClickSelectInfluencer}>
                    Start Accepting
                  </Button>
                ) : (
                  <span className="span-step">3rd Step</span>
                )}
              </div>
            </div>
          </Col>
        </Row>
      </Card>
    </div>
  );
};

export default EmptyHomeCard;
